import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { H2 } from '../shared/type'

export const Section = styled.section``

export const Subsection = styled.div`
  & + & {
    margin-top: ${p => p.theme.space[8]};

    ${mediaQueries.lg} {
      margin-top: ${p => p.theme.space[16]};
    }
  }
`
export const Title = styled(H2)`
  margin: ${p => `0 0 ${p.theme.space[14]}`};
  font-size: ${p => p.theme.fontSizes[8]};

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[16]}`};
  }
`
export const Subtitle = styled.h3`
  color: ${p => p.theme.colors.muted};
  font-size: ${p => p.theme.fontSizes[5]};
  line-height: 1.5;

  margin: ${p => `0 0 ${p.theme.space[6]}`};

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[5]};
    margin: ${p => `0 0 ${p.theme.space[12]}`};
  }
`
