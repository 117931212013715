import React from 'react'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { formatNumber } from '../../utils'
import { H3 } from '../shared/type'
import Tooltip from '../shared/tooltip'
import { Hr as BaseHr } from '../shared/rules'
import BaseLink from '../shared/link'

const Title = styled(H3)`
  position: relative;
  display: inline-block;
  margin: ${p => `0 0 ${p.theme.space[6]}`};
`

const Info = styled.div`
  display: grid;
  grid-gap: ${p => p.theme.space[4]};

  margin-bottom: ${p => p.theme.space[8]};
  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[10]};
  }
`

const InfoLink = styled(BaseLink)`
  width: max-content;
  margin: 0;
  ${p => p.disabled && `text-decoration: line-through;`}
  color: inherit;
  border-bottom: 1px dashed transparent;
  cursor: pointer;

  :hover,
  :focus {
    border-color: ${p => p.theme.colors.text};
  }
`

const Note = styled.div`
  margin-bottom: ${p => p.theme.space[10]};
`

const NoteItem = styled.p`
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: ${p => p.theme.space[4]};
  }
`

const Tooltipped = ({ title, children }) => {
  const theme = useTheme()
  return (
    <Tooltip placement="bottom-start" title={title}>
      <span
        css={css`
          cursor: pointer;
          border-bottom: 1px dashed ${theme.colors.text};

          :hover {
            border-color: rgba(0, 0, 0, 0.3);
          }
        `}
      >
        {children}
      </span>
    </Tooltip>
  )
}

const Hr = styled(BaseHr)`
  margin: 0 0 ${p => p.theme.space[8]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[8]};
  }
`

const Price = ({ yearly, monthly }) => {
  const theme = useTheme()
  return (
    <p
      css={css`
        margin: 0;
        font-size: ${theme.fontSizes[5]};

        strong {
          font-size: ${theme.fontSizes[6]};

          ${mediaQueries.lg} {
            font-size: ${theme.fontSizes[7]};
          }
        }

        small {
          font-size: ${theme.fontSizes[1]};
        }
      `}
    >
      <strong>${yearly}</strong>
      {monthly && (
        <small>
          {' '}
          /{' '}
          <Tooltipped
            title={`Price per month billed yearly or $${monthly} month-to-month`}
          >
            month
          </Tooltipped>
        </small>
      )}
    </p>
  )
}

const Plan = ({ plan, className }) => {
  const theme = useTheme()
  const { name, responses, yearlyPrice, monthlyPrice, features } = plan
  return (
    <div
      css={css`
        border: 2px solid ${theme.colors.black[0]};
        box-shadow: 4px 4px 0 0 ${theme.colors.black[0]};
        padding: ${theme.space[10]} ${theme.space[8]};

        ${mediaQueries.lg} {
          box-shadow: 8px 8px 0 0 ${theme.colors.black[0]};
        }

        ${mediaQueries.xl} {
          padding: ${theme.space[12]} ${theme.space[12]} ${theme.space[10]};
        }
      `}
      className={className}
    >
      <Title>{name}</Title>
      <Info>
        <InfoLink to="#third-party-apps" disabled={!features.third}>
          Third-party apps
        </InfoLink>
        <InfoLink to="#creator-features" disabled={!features.creator}>
          Creator features
        </InfoLink>
        <InfoLink to="#pro-features" disabled={!features.pro}>
          Pro features
        </InfoLink>
      </Info>
      <Note>
        <NoteItem>$1.90 per 1000 sent emails</NoteItem>
        <NoteItem>{formatNumber(responses)} responses / month</NoteItem>
      </Note>
      <Hr />
      <Price yearly={yearlyPrice} monthly={monthlyPrice} />
    </div>
  )
}

export default Plan
