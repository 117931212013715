import React from 'react'
import { Card, CardSet } from '../shared/cards'
import { Section, Subsection, Subtitle, Title } from './section'

const ProFeatures = ({ className }) => (
  <Section id="pro-features" className={className}>
    <Title>Pro features</Title>

    <Subsection>
      <Subtitle>Forms</Subtitle>
      <CardSet storageKey="pricing-pro-features-forms-carousel">
        <Card title="CSS editor">
          Tailor Getform mini landing pages to your branding with the built-in
          CSS editor. Adjust the sizing, spacing, font, and color theme.
        </Card>
        <Card title="Dynamic text replacement">
          Personalize your CTAs using dynamic text. Display your contacts'
          locations, names, and current dates on forms and landing pages.
        </Card>
        <Card title="Skip logic and branching">
          Create online forms that adapt each subsequent page to the options
          selected by respondents.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Automations</Subtitle>
      <CardSet storageKey="pricing-pro-features-automations-carousel">
        <Card title="Form submission">
          Set up follow-up automations to confirm subscription and tag new
          contacts.
        </Card>
        <Card title="New subscriber">
          Create automations to welcome and engage new contacts joining your
          list.
        </Card>
        <Card title="Email link click">
          Build automations triggered when contacts click certain links in your
          emails.
        </Card>
        <Card title="Webhook request">
          Trigger email marketing workflows by real-time events and incoming
          data.
        </Card>
        <Card title="Segment">
          Launch workflows for your contacts by adding them to specific
          segments.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Emails</Subtitle>
      <CardSet storageKey="pricing-pro-features-emails-carousel">
        <Card title="Dynamic text replacement">
          Boost email open rates and CTR by personalizing subject lines, email
          content, and previews with names, locations, and other data.
        </Card>
        <Card title="Countdown timers">
          Add a sense of urgency to your emails with custom countdown timers.
          Set up a fixed-date timer or an evergreen timer to encourage your
          contacts to act fast.
        </Card>
        <Card title="Product cards">
          Promote your products or services via email using clickable product
          cards. Let your customers start their shopping experience directly
          from the inbox.
        </Card>
        <Card title="Scrolling text">
          Add scrolling text to your emails to highlight important
          announcements.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default ProFeatures
