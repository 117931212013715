import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { Card, CardSet } from '../shared/cards'
import {
  Section,
  Subsection as BaseSubsection,
  Subtitle,
  Title,
} from './section'

const Highlight = styled.span`
  background-color: ${p => p.theme.colors.gray[3]};
`

const Subsection = styled(BaseSubsection)`
  & + & {
    margin-top: ${p => p.theme.space[16]};

    ${mediaQueries.lg} {
      margin-top: ${p => p.theme.space[16]};
    }
  }
`

const Features = ({ className }) => (
  <Section id="creator-features" className={className}>
    <Title>Creator features</Title>

    <Subsection>
      <Subtitle>Forms</Subtitle>
      <CardSet storageKey="pricing-creator-features-forms-carousel">
        <Card title="All templates">
          Get started faster with a gallery of form templates prepared by
          professional designers and ready to be used.
        </Card>
        <Card title="Integrations">
          Integrate your online forms and landing page with Google Sheets or
          other apps from the Zapier library.
        </Card>
        <Card title="Custom subdomain">
          Incorporate your branding into the page URL to let your audience know
          it belongs to you: <Highlight>shop</Highlight>
          .getform.com/cake-order-form
        </Card>
        <Card title="Statistics & reports">
          Get a detailed overview of your form performance right in the Getform
          dashboard. Easily switch between the bird's eye view and the granular
          level of reports using convenient charts and filters.
        </Card>
        <Card title="Response summary">
          View responses submitted through each field compiled together in a
          single report. It's a quick way to access average survey response,
          rating value, or all feedback messages submitted to you.
        </Card>
        <Card title="Response details">
          Zoom in at the form responses and analyze every single one of them
          individually. Along with the submitted data, get the context about the
          respondent: their location, device, OS, browser, and UTM.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Contacts</Subtitle>
      <CardSet storageKey="pricing-creator-features-contacts-carousel">
        <Card title="Contact database">
          Keep all your leads generated via Getform in one place. Access your
          contact database, review interaction history, add tags, and filter
          audience segments.
        </Card>
        <Card title="Segments">
          Improve your email marketing ROI with segmentation. Use tags,
          locations, and other data collected via your opt-in forms to create
          personalized campaigns.
        </Card>
        <Card title="Tags">
          Segment your audience using tags. Assign tags via field mapping and
          automations; add and remove tags manually right in your contact
          database.
        </Card>
        <Card title="Interaction history">
          View each contact's interaction history. Find out when and how they
          joined your list, where they are from, and whether they've interacted
          with your emails.
        </Card>
        <Card title="Import">
          Import a list of your email contacts to Getform. Upload emails with
          custom fields via a CSV file, add contacts manually, or import them
          from Mailchimp.
        </Card>
        <Card title="Custom fields">
          Create and manage custom contact fields. Use them for segmentation,
          contact filtering, and dynamic text replacement.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Broadcasts</Subtitle>
      <CardSet storageKey="pricing-creator-features-broadcasts-carousel">
        <Card title="Audience targeting">
          Set up audience filters and conditions to send targeted email
          campaigns.
        </Card>
        <Card title="Targeting templates">
          Use built-in targeting templates to quickly build segments for your
          campaign.
        </Card>
        <Card title="Performance report">
          See how many people have received, opened, and clicked through your
          email.
        </Card>
        <Card title="Scheduling">
          Send email broadcasts right away or schedule them for a future date
          and time.
        </Card>
      </CardSet>
    </Subsection>

    <Subsection>
      <Subtitle>Emails</Subtitle>
      <CardSet storageKey="pricing-creator-features-emails-carousel">
        <Card title="Full-featured email editor">
          Use the built-in email editor to design beautiful emails with your
          branding.
        </Card>
        <Card title="Template gallery">
          Get started faster with a gallery of customizable welcome email
          templates.
        </Card>
        <Card title="Draft sharing">
          Share email drafts with your teammates before sending them to
          subscribers.
        </Card>
        <Card title="AI text generator">
          Quickly generate text for your emails from scratch or refine your own
          copy.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default Features
