import React from 'react'
import styled from '@emotion/styled'
import Tippy from '@tippyjs/react/headless'

const Box = styled.div`
  position: relative;
  width: ${p => p.theme.sizes.xs};
  padding: ${p => p.theme.space[6]};
  font-size: ${p => p.theme.fontSizes[1]};
  background: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.shadows.overlay};
`

const Tooltip = ({ title, children, ...props }) => {
  return (
    <Tippy {...props} render={attrs => <Box {...attrs}>{title}</Box>}>
      {children}
    </Tippy>
  )
}

export default Tooltip
