import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { H1 } from '../shared/type'
import BasePlans from './plans'

const Section = styled.section``

const Heading = styled(H1)`
  margin: 0 0 ${p => p.theme.space[8]};
`

const Description = styled.p`
  font-size: ${p => p.theme.fontSizes[3]};
  margin: ${p => `0 0 ${p.theme.space[8]}`};
  max-width: 45.5rem;

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[16]}`};
  }
`

const Plans = styled(BasePlans)`
  margin-bottom: ${p => p.theme.space[14]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[20]};
  }
`

const PricingHero = ({ className }) => (
  <Section className={className}>
    <Heading>Pricing</Heading>
    <Description>
      Choose Creator for lead generation, promotion, and research. Upgrade to
      Pro for a comprehensive email marketing platform with advanced features.
    </Description>
    <Plans />
  </Section>
)

export default PricingHero
