import React from 'react'
import styled from '@emotion/styled'
import { Thumbnails, Slides, useCarousel } from '../shared/carousel'
import Plan from './plan'

const Container = styled.div``

const PlanContainer = styled.div`
  min-width: 100%;
  padding: ${p => `0 ${p.theme.space[2]} ${p.theme.space[1]} 0`};
`

const PlanSlide = ({ plan, className }) => (
  <PlanContainer className={className}>
    <Plan plan={plan} />
  </PlanContainer>
)

const PlanSlides = styled(Slides)``

const PlanThumbnail = styled.div`
  padding: ${p => `${p.theme.space[4]} 0`};
  flex: 1;

  :before {
    content: '';
    display: block;
    border: ${p => `2px solid ${p.theme.colors.black[0]}`};
    opacity: ${p => !p.selected && 0.3};
  }
`

const PlanThumbnails = styled(Thumbnails)`
  margin-bottom: ${p => p.theme.space[8]};

  ${PlanThumbnail} + ${PlanThumbnail} {
    margin-left: ${p => p.theme.space[4]};
  }
`

const PlanCarousel = ({ plans, className }) => {
  const { slidesRef, selectedIndex, select } = useCarousel('plan-carousel')

  return (
    <Container className={className}>
      <PlanThumbnails>
        {plans.map((p, index) => (
          <PlanThumbnail
            selected={index === selectedIndex}
            onClick={() => select(index)}
            key={index}
          />
        ))}
      </PlanThumbnails>

      <PlanSlides ref={slidesRef}>
        {plans.map((p, index) => (
          <PlanSlide plan={p} key={index} />
        ))}
      </PlanSlides>
    </Container>
  )
}

export default PlanCarousel
