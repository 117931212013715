import React from 'react'
import { Card, CardSet } from '../shared/cards'
import { Section, Subsection, Subtitle, Title } from './section'

const ProFeatures = ({ className }) => (
  <Section id="third-party-apps" className={className}>
    <Title>Third-party apps</Title>

    <Subsection>
      <Subtitle>Forms</Subtitle>
      <CardSet storageKey="pricing-pro-features-forms-carousel">
        <Card title="Email marketing">
          Connect Getform with Klaviyo, Mailchimp, Convertkit, and other email
          marketing apps.
        </Card>
        <Card title="CRM & automation">
          Make use of built-in integrations with marketing platforms like
          Hubspot and Keap.
        </Card>
        <Card title="Customer experience">
          Optimize your customer experience by connecting Intercom and Omnisend.
        </Card>
      </CardSet>
    </Subsection>
  </Section>
)

export default ProFeatures
